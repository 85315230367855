<template>
  <div>
    <div class='row'>
      <div class='col-lg-12'>
        <div class='card card-top card-top-primary'>
          <div class='card-header'>
            <vb-headers-card-header :data="{ title: 'Şikayetler' }" />
          </div>
          <div class='card-body'>
            <div class='table-responsive text-nowrap'>
              <a-table :columns='columns'
                       :data-source='ComplaintTable'
                       :pagination=false
                       :row-key='(record) => record.id'
                       :loading='loading'
                       :locale='{emptyText: "Henüz Aktivite Yok"}'
              >
                <template #id='{ record }'>
                  <span class='font-weight-bold'>{{ record.id }}</span>
                </template>
                <template #complainant='{ record }'>
                  <span>{{ record.complainant.name }}</span>
                </template>
                <template #complained='{ record }'>
                  <span>{{ record.complained.name }}</span>
                </template>
                <template #complainTitle='{record}'>
                  <span>{{ record.title }}</span>
                </template>
                <template #complaintDate='{ record }'>
                  <span>{{ moment(record.complaintDate).format('LL, HH:mm') }}</span>
                </template>
                <template #states='{ record }'>
                  <span :class="record.complaintState === 'SEEN' ? 'badge badge-primary' : 'badge badge-danger'">
                    {{ record.complaintState === 'SEEN' ? 'Görüldü' : 'Görülmedi' }}
                  </span></template>
                <template #operation='{ record }'>
                  <router-link
                    :to='`/sikayet-detaylari/${record.id}`'
                    tag='a'
                    type='button'
                    class='btn btn-primary mr-1'
                  >
                    <i class='fe fe-settings text-white' />
                  </router-link>
                </template>
              </a-table>
            </div>
            <div class='clearfix mt-2'>
              <a-pagination
                class='float-right'
                @change='loadComplaints'
                v-model:page-size='pagination.recordsPerPage'
                :default-page-size='pagination.recordsPerPage'
                v-model:current='pagination.currentPage'
                :default-current='pagination.currentPage'
                :total='pagination.totalRecords'
                :show-total='total => `Toplam ${total} kayıt`'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CustomerServiceAdministration } from '../../services/api-service'
import moment from 'moment'
import trLocale from 'moment/locale/tr'
import { useStore } from 'vuex'
moment.updateLocale('tr', trLocale)

export default {
  name: 'Sikayetler',
  data() {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        slots: { customRender: 'id' },
      },
      {
        title: 'Şikayet Eden Kişi',
        dataIndex: 'complainant',
        slots: { customRender: 'complainant' },
      },
      {
        title: 'Şikayet Edilen Kişi',
        dataIndex: 'complained',
        slots: { customRender: 'complained' },
      },
      {
        title: 'Konu Başlığı',
        dataIndex: 'title',
        slots: { customRender: 'title' },
      },
      {
        title: 'Şikayet Tarihi',
        dataIndex: 'complaintDate',
        slots: { customRender: 'complaintDate' },
      },
      {
        title: 'Şikayet Durumu',
        dataIndex: 'complaintState',
        slots: { customRender: 'states' },
      },
      {
        title: 'Yönet',
        dataIndex: 'operation',
        slots: { customRender: 'operation' },
        class: 'text-right',
      },
    ]

    return {
      ComplaintTable: [],
      columns,
      loading: false,
      pagination: { totalPages: 1, currentPage: 1, totalRecords: 0, recordsPerPage: 1 },
    }
  },
  created: function() {
    this.moment = moment
  },
  mounted() {
    this.loadComplaints()
  },
  methods: {
    async loadComplaints() {
      const store = useStore()
      this.loading = true

      try {
        const { data } = await CustomerServiceAdministration.listComplaints(this.pagination.currentPage)
        this.ComplaintTable = data.complaintList
        this.pagination = data.pagination

        const find = data.complaintList.find(x => x.complaintState === 'NOT_SEEN_YET')
        if(find){
          store.commit('CHANGE_SETTING', {
            setting: 'hasNewNotSeenComplaint',
            value: true,
          })
        }else{
          store.commit('CHANGE_SETTING', {
            setting: 'hasNewNotSeenComplaint',
            value: false,
          })
        }
      } catch (e){
        console.log(e)
      }

      this.loading = false
    },
  },
}
</script>
